import React, { Fragment, PropsWithChildren, useEffect } from "react"
import { Link, Script, useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"

import { isBrowser } from "utils"

import upcity from "images/upcity.png"
import logo from "images/logo.svg"
import topAppDevelopmentCompanies from "images/top-app-development-companies.png"
import goodfirms from "images/goodfirms.svg"
import googleLogo from "images/GoogleGLogo.svg"
import linkedin from "images/social-media/linkedin.svg"
import instagram from "images/social-media/instagram.svg"
import facebook from "images/social-media/facebook.svg"
import youtube from "images/social-media/youtube.svg"
import heart from "images/heart.svg"

export default function Footer() {
  const year = new Date().getFullYear()

  const data = useStaticQuery<Queries.FooterQuery>(graphql`
    query Footer {
      allContentfulLegalDocument {
        nodes {
          slug
          title
        }
      }
    }
  `)

  if (data.allContentfulLegalDocument === null) {
    throw Error("Failed to load legal documents for the footer.")
  }

  useEffect(() => {
    return () => {
      // @ts-ignore
      if (window.CLUTCHCO) window.CLUTCHCO.Destroy()
    }
  }, [])

  return (
    <footer className="container mt-auto pb-10 pt-32">
      <div className="mb-8 flex flex-col items-center justify-between gap-y-6 lg:flex-row">
        <Link className="shrink-0" to="/">
          <img className="max-h-8" alt="logo" src={logo} />
        </Link>

        <nav className="text-center text-sm">
          <FooterLink to="/case-studies/">Case Studies</FooterLink>
          <FooterLink to="/services/">Services</FooterLink>
          <FooterLink to="/about/">About</FooterLink>
          <FooterLink to="/blog/">Blog</FooterLink>
          <FooterLink to="/careers/">Careers</FooterLink>
          <FooterLink to="/contact/">Contact</FooterLink>
        </nav>

        <nav className="flex gap-x-3">
          <SocialMediaLink href="https://www.linkedin.com/company/pieoneers">
            <img src={linkedin} alt="LinkedIn" />
          </SocialMediaLink>

          <SocialMediaLink href="https://www.instagram.com/pieoneerssoftware/">
            <img src={instagram} alt="Instagram" />
          </SocialMediaLink>

          <SocialMediaLink href="https://www.facebook.com/pieoneers/">
            <img src={facebook} alt="Facebook" />
          </SocialMediaLink>

          <SocialMediaLink href="https://www.youtube.com/channel/UCwsrq_kKBfkGa0nJoI2E7nQ/featured">
            <img src={youtube} alt="YouTube" />
          </SocialMediaLink>
        </nav>
      </div>

      <div className="flex flex-wrap items-center justify-center gap-x-12 gap-y-6 lg:flex-row">
        <div
          className="clutch-widget w-48 shrink-0"
          data-url="https://widget.clutch.co"
          data-widget-type="2"
          data-height="45"
          data-nofollow="true"
          data-expandifr="true"
          data-scale="100"
          data-clutchcompany-id="21335"
        />

        <BadgeLink
          className="flex items-center justify-center gap-x-1.5"
          href="https://www.google.com/search?q=pieoneers+software+inc&sca_esv=568900134&authuser=2&source=hp&ei=CnkUZYaSNayWseMPgs2giAg&iflsig=AO6bgOgAAAAAZRSHGqjwQsUrS4O3lWXw4qqAtOpPXeGg&gs_ssp=eJzj4tZP1zcsSzfJyjApMWC0UjWoMDWxMDM3NLS0TDNJsTBJM7UyqEgxT7Q0NkmxTE5MTTE3TEn0EivITM3PS00tKlYozk8rKU8sSlXIzEsGAEORF5o&oq=pieoneers&gs_lp=Egdnd3Mtd2l6IglwaWVvbmVlcnMqAggBMgUQABiABDILEC4YgAQYxwEYrwEyBRAAGIAEMgYQABgeGA0yBhAAGB4YDTIKEAAYHhgNGA8YCjIIEAAYHhgNGA9I8yVQAFiWFHAAeACQAQCYAaEBoAGAB6oBAzYuM7gBAcgBAPgBAcICCxAuGIAEGMcBGNEDwgIFEC4YgATCAgcQABiABBgKwgIHEC4YgAQYCsICDRAuGIAEGMcBGNEDGAo&sclient=gws-wiz#lrd=0x548671199f4d84f5:0xd7a934d9caed71da,1,,,,"
        >
          <img src={googleLogo} alt="Google logo" />
          <div className="flex flex-col gap-y-1">
            <div className="flex items-center gap-x-2.5">
              <p className="font-jakarta text-lg font-bold text-[#FEA500]">
                5.0
              </p>
              <div className="flex items-center gap-x-1">
                <GoogleBadgeStar />
                <GoogleBadgeStar />
                <GoogleBadgeStar />
                <GoogleBadgeStar />
                <GoogleBadgeStar />
              </div>
            </div>
            <span className="text-xs text-[#868686]">See all our reviews</span>
          </div>
        </BadgeLink>

        <BadgeLink
          className="w-20 text-center"
          href="https://upcity.com/profiles/pieoneers-software-inc"
        >
          <img className="inline-block" src={upcity} alt="Upcity Badge" />
        </BadgeLink>

        <BadgeLink
          className="w-36"
          href="https://www.goodfirms.co/company/pieoneers"
        >
          <img src={goodfirms} alt="GoodFirms Badge" />
        </BadgeLink>

        <BadgeLink
          className="w-28"
          href="https://topappdevelopmentcompanies.com/ca/directory/app-developers/canada"
        >
          <img
            src={topAppDevelopmentCompanies}
            alt="Top App Development Companies Badge"
          />
        </BadgeLink>
      </div>

      <div>
        <p className="mt-12 w-full shrink-0 text-center text-2xs lg:w-auto">
          Pieoneers Software Inc &copy; {year} &nbsp;–&nbsp;{" "}
          <Link to={"/cookies/"}>Cookie Settings</Link>
          <>
            ,{" "}
            {data.allContentfulLegalDocument.nodes.map(
              ({ slug, title }, index, { length }) => (
                <Fragment key={slug}>
                  <Link to={`/${slug}`}>{title}</Link>
                  {index < length - 1 && <>, </>}
                </Fragment>
              )
            )}
          </>
        </p>
      </div>

      <Script
        src="https://widget.clutch.co/static/js/widget.js"
        onLoad={() => {
          // @ts-ignore
          if (isBrowser() && window.CLUTCHCO) window.CLUTCHCO.Init()
        }}
      />
    </footer>
  )
}

const FooterLink = ({ to, children }: PropsWithChildren<{ to: string }>) => (
  <Link
    className="inline-block px-2 py-1 font-bold text-primary hover:underline"
    to={to}
  >
    {children}
  </Link>
)

const SocialMediaLink = ({
  href,
  children
}: PropsWithChildren<{ href: string }>) => (
  <a
    className="inline-block transition-transform hover:scale-110"
    href={href}
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    {children}
  </a>
)

const BadgeLink = ({
  className,
  href,
  children
}: PropsWithChildren<{ className?: string; href: string }>) => (
  <a
    className={classNames("inline-block", className)}
    href={href}
    target="_blank"
    rel="noreferrer"
  >
    {children}
  </a>
)

function GoogleBadgeStar() {
  return (
    <svg
      viewBox="0 0 22 20"
      className="aspect-[10/11] w-6 fill-[#FEA500]"
      aria-hidden="true"
    >
      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
    </svg>
  )
}
